import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpService } from './http.service';
import { API_END_POINTS } from '@constants/constant';
import { Router } from '@angular/router';
import { LocalStorageServiceService } from './local-storage-service.service';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenSubject: BehaviorSubject<string | null>;
  private refreshTokenSubject: BehaviorSubject<string | null>;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private router: Router,
    private localStorageService: LocalStorageServiceService,
    private msalService: MsalService
  ) {
    this.tokenSubject = new BehaviorSubject<string | null>(
      this.localStorageService.getItem('accessToken')
    );
    this.refreshTokenSubject = new BehaviorSubject<string | null>(
      localStorage.getItem('refreshToken')
    );
  }

  getToken(): string | null {
    return this.tokenSubject.value;
  }

  refreshToken(): Observable<string> {
    const token = this.refreshTokenSubject.value;
    return from(
      this.httpService.postRequest(API_END_POINTS.REFRESH_TOKEN, { token })
    ).pipe(
      tap((response: any) => {
        const newToken = response.data.accessToken;
        this.tokenSubject.next(newToken);
        this.localStorageService.setAccessToken(newToken);
      })
    );
  }

  logout() {
    this.tokenSubject.next(null);
    this.refreshTokenSubject.next(null);
    this.localStorageService.clearLocalStorage();
    this.msalService.instance.logoutRedirect();
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userRoleId');
    localStorage.removeItem('userName');
    this.router.navigate(['/login']);
  }
}
