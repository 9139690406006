import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { filter, Subscription } from 'rxjs';
import { API_END_POINTS } from '../../../constants/constant';
import { LineData } from '../../../interfaces/line.interface';
import { FactoryData, PlantData } from '../../../interfaces/plant.interface';
import { CommonService } from '../../../services/common.service';
import { HeaderService } from '../../../services/header.service';
import { HttpService } from '../../../services/http.service';
import { ButtonComponent } from '../../../shared/button/button.component';
import { get } from 'lodash';
import { SortByComponent } from '@shared/sort-by/sort-by.component';

@Component({
  selector: 'app-header-filter-modal',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    ButtonComponent,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    MultiSelectModule,
    SortByComponent,
  ],
  templateUrl: './header-filter-modal.component.html',
  styleUrls: ['./header-filter-modal.component.scss'],
})
export class HeaderFilterModalComponent implements OnInit, OnDestroy {
  lineId: any;
  plantId!: any;
  factoryId: any;
  plantData!: PlantData[];
  cityId!: number;
  selectedLines: any;
  isSubmitted = false;
  locationForm: FormGroup;
  linesData!: LineData[];
  filteredLines!: LineData[];
  factoryData!: FactoryData[];
  lineSubscription?: Subscription;
  factorySubscription?: Subscription;
  plantName: string = 'Neemrana, Rajasthan';
  private subscriptions: Subscription = new Subscription();
  @Input() chartHeading: string = '';
  isDisabled: boolean = false;
  isAllDisabled: boolean = false;

  isDailyLineRoute: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ref: DynamicDialogRef,
    private httpService: HttpService,
    public config: DynamicDialogConfig,
    private headerService: HeaderService,
    private commonService: CommonService
  ) {
    this.locationForm = this.fb.group({
      plant: [null, Validators.required],
      factory: [null],
      line: [null],
    });
  }

  ngOnInit(): void {
    this.checkActiveRoute(this.router.url);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.checkActiveRoute(event.urlAfterRedirects);
      });
    this.commonService.currentChartHeading.subscribe((heading) => {
      this.chartHeading = heading;
    });

    this.plantId = localStorage.getItem('plantId');

    this.subscriptions.add(
      this.headerService.plantId.subscribe((plantId: string | null) => {
        this.plantId = plantId;
        this.updateSelectedPlant();
      })
    );

    this.commonService.currentChartHeading.subscribe((heading) => {
      this.chartHeading = heading;
      if (
        this.chartHeading === 'User Management' ||
        this.chartHeading === 'Alarm Code Definitions' ||
        this.chartHeading === 'Line Management' ||
        this.chartHeading === 'Production Loss Reason' ||
        this.chartHeading === 'Alerts Management'
      ) {
        this.isDisabled = true;
        this.isAllDisabled = false;
        this.headerService.removeLineId();
        this.plantId = localStorage.getItem('plantId');
        if (this.plantId) this.headerService.setPlantId(this.plantId);
        this.factoryId = localStorage.getItem('factoryId');
        if (this.factoryId) this.headerService.setFactoryId(this.factoryId);
        this.lineId = localStorage.getItem('lineId');
        if (this.lineId) this.headerService.setLineId(this.lineId);
      } else if (this.chartHeading === 'Manage Plants') {
        this.plantId = null;
        this.factoryId = null;
        this.lineId = null;
        this.isAllDisabled = true;
        this.isDisabled = false;
      } else {
        this.isAllDisabled = false;
        this.isDisabled = false;
        this.plantId = localStorage.getItem('plantId');
        if (this.plantId) this.headerService.setPlantId(this.plantId);
        this.factoryId = localStorage.getItem('factoryId');
        if (this.factoryId) this.headerService.setFactoryId(this.factoryId);
        this.lineId = localStorage.getItem('lineId');
        if (this.lineId) this.headerService.setLineId(this.lineId);
      }
      this.headerService.fetchSource.next();
    });

    this.factoryId = localStorage.getItem('factoryId');

    this.lineId = localStorage.getItem('lineId');

    const storedLines = localStorage.getItem('selectedLines');
    if (storedLines) {
      this.selectedLines = JSON.parse(storedLines);
    }

    this.subscriptions.add(
      this.headerService.lineId.subscribe((lineId: string | null) => {
        this.lineId = lineId;
        this.updateSelectedLine();
      })
    );

    this.subscriptions.add(
      this.headerService.factoryId.subscribe((factoryId: string | null) => {
        this.factoryId = factoryId;
        this.updateSelectedFactory();
      })
    );

    this.subscriptions.add(
      this.headerService.lineIds.subscribe((lineIds: string[] | null) => {
        if (lineIds) {
          this.selectedLines = lineIds;
          localStorage.setItem(
            'selectedLines',
            JSON.stringify(this.selectedLines)
          );
        }
      })
    );

    this.getPlantData();
    this.getFactoryData();
    if (this.factoryId) {
      this.getLineData();
    } else if (this.plantId) {
      this.getLineDataByPlant();
    }
  }

  updateSelectedPlant(): void {
    const selectedPlant = this.plantData?.find(
      (plant: { id: number }) => plant.id === +this.plantId
    );
    if (selectedPlant) {
      this.locationForm
        .get('plant')
        ?.setValue(selectedPlant, { emitEvent: false });
    }
  }

  updateSelectedFactory(): void {
    const selectedFactory = this.factoryData?.find(
      (factory: { id: number }) => factory.id === +this.factoryId
    );
    if (selectedFactory) {
      this.locationForm
        .get('factory')
        ?.setValue(selectedFactory, { emitEvent: false });
      this.filterLines(selectedFactory);
    }
  }

  updateSelectedLine(): void {
    const selectedLine = this.linesData?.find(
      (line: { id: number }) => line.id === +this.lineId
    );
    if (selectedLine) {
      this.locationForm
        .get('line')
        ?.setValue(selectedLine, { emitEvent: false });
    }
  }

  filterLines(selectedFactory: FactoryData) {
    if (selectedFactory) {
      this.filteredLines = this.linesData?.filter(
        (line) => line.factoryId === selectedFactory?.id
      );
    } else {
      this.filteredLines = this.linesData;
    }
  }

  onPlantChange(event: { value: PlantData }) {
    if (event) {
      this.plantId = event.value.id;
      this.getFactoryData();
      this.getLineData();
    }
  }

  onFactoryChange(event: { value: FactoryData }) {
    const selectedFactory = event.value;
    this.factoryId = get(event, 'value.id');
    this.filterLines(selectedFactory);
    this.locationForm.get('line')?.setValue(null);
    this.selectedLines = [];
    this.getLineData();
  }

  getPlantData() {
    const url = API_END_POINTS.GET_PLANT;
    let params = new HttpParams().set('PageNumber', 0).set('PageSize', 0);
    this.httpService
      .getRequest(url, null, { params })
      .then((response) => {
        if (response) {
          this.plantData = response.data.filter(
            (plant: { isActive: boolean }) => plant.isActive
          );
          this.updateSelectedPlant();
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  getFactoryData() {
    if (!this.plantId) return;
    const url = `${API_END_POINTS.GET_FACTORY_BY_PLANTID}${this.plantId}`;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.factoryData = response.data;
          this.updateSelectedFactory();
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  getLineData() {
    if (!this.factoryId) return;
    const url = `${API_END_POINTS.GET_LINE_BY_FACTORYID}${this.factoryId}`;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.linesData = response;
          this.filteredLines = this.linesData;
          this.updateSelectedLine();
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  onViewLines() {
    this.isSubmitted = true;
    if (this.locationForm.valid) {
      this.ref.close({
        form: this.locationForm,
        selectedLines: this.selectedLines,
      });
    }
  }

  get factoryControl() {
    return this.locationForm.get('factory');
  }

  get isSaveViewDisabled() {
    const factorySelected = !!this.locationForm.get('factory')?.value;
    const lineSelected = !!this.locationForm.get('line')?.value;
    return !(factorySelected || lineSelected);
  }

  onLineChange(selectedLine: LineData) {
    this.lineId = selectedLine.id;
    localStorage.setItem('lineId', this.lineId);
  }

  private checkActiveRoute(url: string): void {
    if (url === '/chart/hourly-production') {
      this.isDailyLineRoute = true;
    } else {
      this.isDailyLineRoute = false;
    }
  }

  onClearAllClick() {
    this.ref.close(null);
  }

  ngOnDestroy(): void {
    if (this.factorySubscription) {
      this.factorySubscription?.unsubscribe();
    }
    if (this.lineSubscription) this.lineSubscription?.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  getLineDataByPlant() {
    const url = `${API_END_POINTS.GET_ALL_PLANT_LINES}${this.plantId}`;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.linesData = response;
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }
}
