<div class="main-plan-container" [formGroup]="locationForm">
  <div class="flex heading-container">
    <h3 class="heading">{{ "Filter By" }}</h3>
  </div>
  <label for="plant" class="label-txt"
    >{{ "Plant" | translate }} <span class="col-red">*</span>
  </label>
  <p-dropdown
    [options]="plantData"
    formControlName="plant"
    optionLabel="name"
    placeholder="{{ 'Select Plant' | translate }}"
    class="block w-full"
    (onChange)="onPlantChange($event)"
    [disabled]="isAllDisabled"
    [ngClass]="{ 'disabled-dropdown': isAllDisabled }"
    [showClear]="false"
  ></p-dropdown>
  <div
    *ngIf="locationForm.get('plant')?.hasError('required') && isSubmitted"
    class="error"
  >
    Plant is required.
  </div>
  <label for="factory" class="label-txt">{{ "FACTORY" | translate }} </label>
  <p-dropdown
    [options]="factoryData"
    formControlName="factory"
    optionLabel="factoryName"
    placeholder="{{ 'SELECT_FACTORY' | translate }}"
    class="block w-full"
    (onChange)="onFactoryChange($event)"
    [disabled]="isAllDisabled"
    [ngClass]="{ 'disabled-dropdown': isAllDisabled }"
    [showClear]="false"
  >
  </p-dropdown>
  <label for="line" class="label-txt">{{ "LINE" | translate }}</label>
  <p-dropdown
    *ngIf="
      !(
        chartHeading === 'Daily Production' ||
        chartHeading === 'Productivity Status' ||
        chartHeading === 'Bekido Status Details'
      ) || isDailyLineRoute
    "
    [options]="filteredLines"
    formControlName="line"
    optionLabel="lineName"
    placeholder="Select Lines"
    [disabled]="isDisabled || isAllDisabled"
    [ngClass]="{ 'disabled-dropdown': isDisabled || isAllDisabled }"
  ></p-dropdown>
  <div
    *ngIf="
      (chartHeading === 'Daily Production' ||
        chartHeading === 'Productivity Status' ||
        chartHeading === 'Bekido Status Details') &&
      !isDailyLineRoute
    "
    class="multiselect-dropdown"
  >
    <p-multiSelect
      [options]="filteredLines"
      [(ngModel)]="selectedLines"
      optionLabel="lineName"
      placeholder="Select Lines"
      [ngModelOptions]="{ standalone: true }"
    ></p-multiSelect>
  </div>
  <div class="flex justify-between mt-6">
    <div class="w-1/2 pr-2">
      <app-button
        [name]="'Clear all'"
        (onClick)="onClearAllClick()"
        class="w-full"
        [btnClass]="'cancel-btn'"
      ></app-button>
    </div>
    <div class="w-1/2 pl-2">
      <app-button
        [name]="'Apply'"
        (onClick)="onViewLines()"
        class="w-full"
        [btnClass]="'save-btn'"
        [disabled]="isSaveViewDisabled"
      ></app-button>
    </div>
  </div>
</div>
