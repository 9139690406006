<p-sidebar
  [visible]="(sidebarService.isVisible | async)!"
  (visibleChange)="handleVisibleChange($event)"
  [baseZIndex]="10000"
  [modal]="false"
  [closeOnEscape]="false"
>
  <div class="flex flex-col h-full">
    <div class="container-padding">
      <div class="mb-5 flex">
        <img
          src="/assets/images/image 5.svg"
          alt="Logo"
          class="h-12 mx-auto cursor-pointer"
          (click)="onDashboardClick()"
        />
        <img
          *ngIf="isMobileOrTabletView"
          src="/assets/images/close-img.svg"
          (click)="onCloseIconClick()"
        />
      </div>
      <ul class="flex-grow p-0 item-container">
        <ng-container *ngFor="let item of sideBarContent">
          <li
            (click)="
              item.subItems ? toggleDropdown(item) : navigate(item.routeLink[0])
            "
            [ngClass]="{
              'active-item text-blue': isActiveRoute(item.routeLink)
            }"
            class="sidebar-item flex items-center cursor-pointer pt-3 pb-3 relative"
          >
            <img
              [src]="
                isActiveRoute(item.routeLink)
                  ? item.activeIconPath
                  : item.iconPath
              "
              alt="{{ item.name }}"
              class="h-5 w-5 mr-4 image-content"
            />
            <p>{{ item.name }}</p>
            <img
              *ngIf="item.subItems"
              [src]="
                item.expanded
                  ? '/assets/images/arrow-up.svg'
                  : '/assets/images/arrow-down.svg'
              "
              alt="Dropdown Icon"
              class="h-5 w-5 ml-auto mr-1"
            />
          </li>
          <ul *ngIf="item.expanded" class="pl-0">
            <li
              *ngFor="let subItem of item.subItems"
              (click)="navigate(subItem.routeLink[0])"
              [ngClass]="{
                'active-item text-blue': isActiveRoute(subItem.routeLink)
              }"
              class="sidebar-item flex items-center cursor-pointer pt-3 pb-3 relative"
            >
              <p class="pl-8">{{ subItem.name }}</p>
            </li>
          </ul>
        </ng-container>
      </ul>
    </div>
    <div class="mt-auto">
      <div class="flex items-center user-info">
        <div class="w-66">
          <p class="user-name mb-1">{{ userName }}</p>
        </div>
        <img
          [src]="'/assets/images/logout-img.svg'"
          class="cursor-pointer"
          (click)="onLogout()"
        />
      </div>
    </div>
  </div>
</p-sidebar>
