import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private _isVisible = new BehaviorSubject<boolean>(true);
  public isVisible = this._isVisible.asObservable() ;

  toggle() {
    this._isVisible.next(!this._isVisible.value);
  }

  close() {
    this._isVisible.next(false);
  }

  open() {
    this._isVisible.next(true);
  }

  getVisibilityValue(): boolean {
    return this._isVisible.value;
  }
}
