import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SidebarModule } from 'primeng/sidebar';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../../features/modals/confirmation-modal/confirmation-modal.component';
import { SuccessModalComponent } from '../../features/modals/success-modal/success-modal.component';
import { SidebarService } from './sidebar.service';
import { LocalStorageServiceService } from '@services/local-storage-service.service';
import { HttpService } from '@services/http.service';
import { API_END_POINTS } from '@constants/constant';
import { HttpParams } from '@angular/common/http';
import { USER_ROLE_ID } from '@constants/meta-data';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [SidebarModule, CommonModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit, OnDestroy {
  activeRoute!: string;
  ref!: DynamicDialogRef;
  isMobileOrTabletView = false;
  isMobileView: boolean = false;
  confirmSubscription?: Subscription;
  sideBarContent: any = [];
  adminSideBarContent: any = [
    {
      name: 'Home',
      routeLink: ['/dashboard'],
      iconPath: '/assets/images/home-2.svg',
      activeIconPath: '/assets/images/document-active.svg',
    },
    {
      name: 'Daily Production',
      routeLink: ['/chart/daily-production', '/chart/hourly-production'],
      iconPath: '/assets/images/building.svg',
      activeIconPath: '/assets/images/building-active.svg',
    },
    {
      name: 'Monthly Production',
      routeLink: ['/chart/monthly-production'],
      iconPath: '/assets/images/buildings.svg',
      activeIconPath: '/assets/images/buildings-active.svg',
    },
    {
      name: 'Module Equipment',
      routeLink: [
        '/chart/module-equipment',
        '/chart/module-equipment/quality-analysis',
      ],
      iconPath: '/assets/images/setting-4.svg',
      activeIconPath: '/assets/images/setting-active.svg',
    },
    {
      name: 'Eq. Failure Downtime',
      routeLink: ['/chart/eq-failure-downtime'],
      iconPath: '/assets/images/sidebar-bottom.svg',
      activeIconPath: '/assets/images/sidebar-bottom-active.svg',
    },
    {
      name: 'Line Status',
      routeLink: ['/chart/line-status'],
      iconPath: '/assets/images/document.svg',
      activeIconPath: '/assets/images/document-active.svg',
    },
    {
      name: 'Productivity Status',
      routeLink: [
        '/chart/productivity-status',
        '/chart/productivity-status/daily-bekido',
        '/chart/productivity-status/daily-productivity',
      ],
      iconPath: '/assets/images/productivity-inactive.svg',
      activeIconPath: '/assets/images/productivity-active.svg',
    },
    {
      name: 'User Management',
      routeLink: ['/user-management'],
      iconPath: '/assets/images/user-profile-img.svg',
      activeIconPath: '/assets/images/user-profile-active-img.svg',
    },
    {
      name: 'Line Management',
      routeLink: ['/chart/line-management'],
      iconPath: '/assets/images/chart.svg',
      activeIconPath: '/assets/images/chart-active.svg',
    },
    {
      name: 'NG Code Definitions',
      routeLink: ['/chart/ng-code'],
      iconPath: '/assets/images/hashtag-img.svg',
      activeIconPath: '/assets/images/hashtag-active-img.svg',
    },
    {
      name: 'Alarm Code Definitions',
      routeLink: ['/chart/alarm-code'],
      iconPath: '/assets/images/chart-success.svg',
      activeIconPath: '/assets/images/chart-success-active.svg',
    },
    // {
    //   name: 'Progress Management',
    //   routeLink: ['/chart/ms-progress'],
    //   iconPath: '/assets/images/alerts-img.svg',
    //   activeIconPath: '/assets/images/setting-active-img.svg',
    // },
    {
      name: 'Alerts Management',
      routeLink: ['/chart/alerts-management'],
      iconPath: '/assets/images/alerts-img.svg',
      activeIconPath: '/assets/images/alert-management-active-img.svg',
    },
    {
      name: 'Safety Security',
      routeLink: ['/safety-security'],
      iconPath: '/assets/images/security-safe-inactive-img.svg',
      activeIconPath: '/assets/images/security-safe.svg',
    },
    {
      name: 'Configurations',
      iconPath: '/assets/images/setting-img.svg',
      activeIconPath: '/assets/images/setting-active-img.svg',
      expanded: false,
      subItems: [
        {
          name: 'Manage Plants',
          routeLink: ['/configuration/manage-plants'],
          iconPath: '/assets/images/sub-item-1.svg',
          activeIconPath: '/assets/images/sub-item-1-active.svg',
        },
        {
          name: 'Prod. Loss Reason',
          routeLink: ['/configuration/loss-reason'],
          iconPath: '/assets/images/sub-item-2.svg',
          activeIconPath: '/assets/images/sub-item-2-active.svg',
        },
        {
          name: 'Line Information Input',
          routeLink: ['/configuration/line-info-input'],
          iconPath: '/assets/images/sub-item-3.svg',
          activeIconPath: '/assets/images/sub-item-3-active.svg',
        },
      ],
    },
  ];

  userSideBarContent: any = [
    {
      name: 'Home',
      routeLink: ['/dashboard'],
      iconPath: '/assets/images/home-2.svg',
      activeIconPath: '/assets/images/document-active.svg',
    },
    {
      name: 'Daily Production',
      routeLink: ['/chart/daily-production', '/chart/hourly-production'],
      iconPath: '/assets/images/building.svg',
      activeIconPath: '/assets/images/building-active.svg',
    },
    {
      name: 'Monthly Production',
      routeLink: ['/chart/monthly-production'],
      iconPath: '/assets/images/buildings.svg',
      activeIconPath: '/assets/images/buildings-active.svg',
    },
    {
      name: 'Module Equipment',
      routeLink: [
        '/chart/module-equipment',
        '/chart/module-equipment/quality-analysis',
      ],
      iconPath: '/assets/images/setting-4.svg',
      activeIconPath: '/assets/images/setting-active.svg',
    },
    {
      name: 'Eq. Failure Downtime',
      routeLink: ['/chart/eq-failure-downtime'],
      iconPath: '/assets/images/sidebar-bottom.svg',
      activeIconPath: '/assets/images/sidebar-bottom-active.svg',
    },
    {
      name: 'Line Status',
      routeLink: ['/chart/line-status'],
      iconPath: '/assets/images/document.svg',
      activeIconPath: '/assets/images/document-active.svg',
    },
    {
      name: 'Productivity Status',
      routeLink: [
        '/chart/productivity-status',
        '/chart/productivity-status/daily-bekido',
        '/chart/productivity-status/daily-productivity',
      ],
      iconPath: '/assets/images/productivity-inactive.svg',
      activeIconPath: '/assets/images/productivity-active.svg',
    },
  ];

  userName: string | null = '';

  constructor(
    private router: Router,
    public sidebarService: SidebarService,
    private dialogService: DialogService,
    private localStorage: LocalStorageServiceService,
    private httpService: HttpService,
    private authService: AuthService
  ) {
    this.handleResize();
    this.checkScreenSize();
    window.addEventListener('resize', this.handleResize.bind(this));
    this.activeRoute = this.router.url;
    this.router.events
      .pipe(
        filter(
          (event: Event): event is NavigationEnd =>
            event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.activeRoute = event.urlAfterRedirects;
        this.expandConfigurationDropdown();
      });
  }

  ngOnInit() {
    const roleId = localStorage.getItem('userRoleId');
    if (roleId == USER_ROLE_ID.ADMIN) {
      this.sideBarContent = this.adminSideBarContent;
    } else this.sideBarContent = this.userSideBarContent;
    this.expandConfigurationDropdown();
    this.userName = localStorage.getItem('userName');
  }

  expandConfigurationDropdown() {
    this.sideBarContent.forEach((item: any) => {
      if (item.subItems) {
        item.expanded = item.subItems.some((subItem: any) =>
          this.isActiveRoute([subItem.routeLink[0]])
        );
      }
    });
  }

  toggleDropdown(item: any) {
    item.expanded = !item.expanded;
  }

  handleVisibleChange(visible: boolean) {
    if (visible !== this.sidebarService.getVisibilityValue()) {
      this.sidebarService.toggle();
    }
  }

  navigate(routeLink: string) {
    this.router.navigate([routeLink]).then(() => {
      if (this.isMobileOrTabletView) {
        this.sidebarService.close();
      }
    });
  }

  onDashboardClick() {
    this.router.navigate(['/dashboard']);
  }

  handleResize() {
    if (window.innerWidth <= 1024) {
      this.sidebarService.close();
    } else {
      this.sidebarService.open();
    }
  }

  onLogout() {
    this.ref = this.dialogService.open(ConfirmationModalComponent, {
      data: {
        headingContent: 'LOGOUT_MESSAGE',
        imageUrl: '/assets/images/archive-plant-img.svg',
      },
      width: '434px',
      styleClass: this.isMobileView
        ? 'custom-modal-border-radius mobile-modal'
        : 'custom-modal-border-radius',
    });
    this.confirmSubscription = this.ref.onClose.subscribe((result) => {
      if (result === 'confirmed') {
        this.onConfirmationLogout();
      }
    });
  }

  onConfirmationLogout() {
    const url = `${API_END_POINTS.LOGOUT}`;
    let params = new HttpParams();
    const token = localStorage.getItem('refreshToken');
    if (token) {
      params = params.set('refreshToken', token);
    }

    this.httpService
      .getRequest(url, null, { params })
      .then((response) => {
        this.authService.logout();
        this.ref = this.dialogService.open(SuccessModalComponent, {
          data: {
            headingContent: '',
            isLoginSuccess: true,
          },
          width: '384px',
          styleClass: this.isMobileView
            ? 'custom-modal-border-radius mobile-modal'
            : 'custom-modal-border-radius',
        });
      })
      .catch((error) => {
        if(error.error?.errors[0]?.message === 'Token is expired.') {
          this.authService.logout();
        }
        console.error('There was an error:', error);
      });
  }

  isActiveRoute(routes: string[]): boolean {
    return routes?.includes(this.activeRoute);
  }

  onCloseIconClick() {
    this.sidebarService.close();
  }

  checkScreenSize() {
    this.isMobileView = window.innerWidth < 768;
    this.isMobileOrTabletView = window.innerWidth <= 1024;
  }

  @HostListener('window:resize')
  onResize() {
    this.handleResize();
    this.checkScreenSize();
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.handleResize.bind(this));
    if (this.confirmSubscription) {
      this.confirmSubscription?.unsubscribe();
    }
  }
}
