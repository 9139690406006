<header
  class="sticky top-0 z-50"
  [ngClass]="{ 'header-padding-left': (sidebarService.isVisible | async) }"
>
  <div
    class="flex justify-between items-center px-4 py-6 bg-white"
    [ngClass]="{
      'display-hidden': (sidebarService.isVisible | async) && isMobileView
    }"
  >
    <div class="sidebar-toggle">
      <i
        class="pi pi-bars text-2xl cursor-pointer"
        (click)="toggleSidebar()"
      ></i>
    </div>
    <div class="heading flex items-center">
      <img
        *ngIf="isBackBtnRequired"
        class="cursor-pointer back-btn-img"
        src="assets/images/arrow-left.svg"
        (click)="onBackClick()"
      />
      <p>{{ chartHeading }}</p>
    </div>
    <div class="flex items-center gap-4 filter-conteiner">
      <ng-container *ngIf="!isMobile; else otherFilter">
        <app-sort-by
          [sortOptions]="plantData"
          [selectedId]="plantId"
          [placeholder]="'Select Plant'"
          [isDisabled]="isAllDisabled"
          (onSortChange)="onPlantChange($event)"
        ></app-sort-by>
        <app-sort-by
        *ngIf="!isDailyProduction"
          [labelName]="'factoryName'"
          [sortOptions]="factoryData"
          [selectedId]="factoryId"
          [placeholder]="'Select Factory'"
          [isDisabled]="isAllDisabled"
          (onSortChange)="onFactoryChange($event)"
        ></app-sort-by>
        <div
        *ngIf="isDailyProduction"
          class="multiselect-dropdown"
        >
          <p-multiSelect
            [options]="factoryData"
            [(ngModel)]="selectedFactory"
            optionLabel="factoryName"
            placeholder="Select Factory"
            (onChange)="onFactorySelectionChange($event)"
          ></p-multiSelect>
        </div>
        <app-sort-by
          *ngIf="
            !(
              chartHeading === 'Daily Production' ||
              chartHeading === 'Productivity Status' ||
              chartHeading === 'Bekido Status Details'
            ) || isDailyLineRoute
          "
          [labelName]="'lineName'"
          [sortOptions]="lineData"
          [selectedId]="lineId"
          [placeholder]="'Select Line'"
          [isDisabled]="isDisabled || isAllDisabled"
          (onSortChange)="onLineChange($event)"
        ></app-sort-by>
        <div
          *ngIf="
            (chartHeading === 'Daily Production' ||
              chartHeading === 'Productivity Status' ||
              chartHeading === 'Bekido Status Details') &&
            !isDailyLineRoute
          "
          class="multiselect-dropdown"
        >
          <p-multiSelect
            [options]="lineData"
            [(ngModel)]="selectedLines"
            optionLabel="lineName"
            placeholder="Select Lines"
            (onChange)="onLineSelectionChange($event)"
          ></p-multiSelect>
        </div>
      </ng-container>
      <ng-template #otherFilter>
        <img
          src="assets/images/filter.svg"
          class="cursor-pointer"
          (click)="onOpenFilterModal()"
        />
      </ng-template>
      <div class="flex items-center gap-3 refresh-continer">
        <img
          class="refresh-img"
          src="/assets/images/refresh-img.svg"
          alt="Refresh"
          (click)="onManualRefresh()"
        />
        <div>
          <div class="flex refresh-content">
            <p class="refresh-text">Auto Refresh</p>
            <app-toggle
              [(isChecked)]="autoRefreshEnabled"
              (isCheckedChange)="onToggleChange($event)"
            >
            </app-toggle>
          </div>
          <p class="date-time">{{ lastApiCallTime }}</p>
        </div>
      </div>
    </div>
  </div>
</header>
